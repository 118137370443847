import React from 'react'
import Layout from '../components/layout-default'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero';

const crumbs = [
    {
        label: "Home",
        link: "",
    },
    {
        label: "Moving",
        link: "moving",
    },
];
const moving = () => {
    return (
        <Layout
            title="Moving | RF Dance"
            description="Discover the best dance studios in Orange County at rfdance.com. From beginners to experts, our classes cater to all levels. Join us and dance like a pro!"
            pathname="moving"
            className="pages"
            // noHero="no-hero"
        >
            <Hero
                titleRed="RF"
                titleWhite="is Moving"
            />
            <div className="content-block">
                <div className="container">
                    <Breadcrumbs crumbs={crumbs} />
                    <div style={{ lineHeight : '1.8'}}>
                        <h2 className='text-center' style={{ fontSize : '40px;'}}>WE ARE MOVING!</h2>
                        <p>
                            RF Dance Center is excited to announce our move to a new, more prominent location! <b>Effective February 28, 2025, you can find us at 2201 W First St, Unit B, Santa Ana, CA 92703.</b> This new studio is conveniently located less than 10 minutes from our current spot, at the intersection of West First St and South Center St. Parking is readily available in the Santa Ana Plaza lot, accessible at the same entrance as the Laundromat. Our entrance faces the plaza.
                        </p>
                        <p>
                            We're thrilled about our new facility's added space, extra bathrooms, and ample parking. Yet, we'll still maintain the same great classes and instructors you've grown to love. Current members will be grandfathered into their current membership rates, while new memberships will cost $149. We can't wait to welcome you to our new home! See the map below for detailed directions.
                        </p>
                        <h4>
                            Location: 2201 W First St, Unit B, Santa Ana, CA 92703
                        </h4>
                        <div style={{width : '80%', margin : '0 auto'}} className="footer_map_section">
                            <iframe
                                style={{border : '2px solid black'}}
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13270.147784562974!2d-117.9020127!3d33.7459479!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd79b8a74b555%3A0x6fcd7e5e3c31e0a6!2s2201%20W%20First%20St%2C%20Unit%20B%2C%20Santa%20Ana%2C%20CA%2092703%2C%20USA!5e0!3m2!1sen!2sus!4v1706659200000!5m2!1sen!2sus"
                                width="100%"
                                height="360"
                                scrolling="no"
                                loading="lazy"
                            ></iframe>
                        </div>

                        <h2 className='text-center' style={{ fontSize : '40px;'}}>¡NOS MUDAMOS!</h2>
                        <p>
                            ¡RF Dance Center se complace en anunciar nuestra mudanza a una nueva y mejor ubicación! <b>A partir del 28 de febrero de 2025, nos encontraremos en 2201 W First St, Unit B, Santa Ana, CA 92703.</b>  Este nuevo estudio está convenientemente ubicado a menos de 10 minutos de nuestra ubicación actual, en la intersección de West First St y South Center St. El estacionamiento está disponible en el lote de Santa Ana Plaza, accesible por la misma entrada que la Lavandería. Nuestra entrada principal da a la plaza.
                        </p>
                        <p>
                            Estamos encantados con el espacio adicional, los baños extra y el amplio estacionamiento de nuestras nuevas instalaciones. Sin embargo, mantendremos las mismas excelentes clases e instructores que tanto les gustan. Los miembros actuales conservarán sus tarifas de membresía actuales, mientras que las nuevas membresías costarán $149. ¡No podemos esperar para darles la bienvenida a nuestro nuevo hogar! Vean el mapa para obtener indicaciones detalladas.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default moving
